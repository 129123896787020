import { calculatePercentage, errorNotifier } from "../../commonHelper";
import { TableNames } from "../../config/Tables";
import { FetchData } from "../../crud";
import { progressByModuleCalculation } from "../Profile/Profile";

export const getCourses = async (studentUid: string) => {
    try {
        const { data, error }: any = await FetchData(TableNames.trainings, ["id", "course:courses(id, course_name, type, thumbnail)", "batch_id"]).eq("student_id", studentUid).order("id", {ascending: false})
        if(error) throw error

        if (data.length > 0) {
              return data
        }
        else {
            return {message: "Not allocated to the batch", status: "not_allocated"}
        }
    } catch (error) {
        return errorNotifier(error)
    }
}

// export const getPaidCourseProgress = async (trainingId: number) => {
//     try {
//         const { data: training_data, error: training_error }: any = await FetchData(TableNames.trainings, ["*", "batch:batches(id, duration, start_date, start_time,user:users(first_name, last_name), course:courses(course_name, duration), city:cities(city_name), branch:branches(branch_name), session:sessions(*))"]).eq("id", trainingId)
//         // edited by frontend
//         if(!training_data?.length){
//            return errorNotifier('no data')
//         }
// // 
//         if (training_error) throw training_error

//         const { data: sessions, error: session_error } = await FetchData(TableNames.sessions, ["id", "attendees", "title", "details", "duration", "session_date", "recent_session", "batch:batches(id, course:courses(course_name,duration), city:cities(city_name), trainer:users(first_name, last_name), branch:branches(branch_name))"]).eq("batch_id", training_data[0]?.batch.id).order("session_date", { ascending: false })
//         // edited by fronted
//         if(!sessions?.length){
//            return errorNotifier('no data')

//         }
//         // 
//         const session_data = sessions?.map((session: any) => ({ ...session, is_present: session?.attendees?.includes(training_data[0].student_id) }));

//         const filtered_students = session_data?.filter((stu: any) => { return stu?.is_present === true })
//         //    edited by fronted
//         if(!filtered_students?.length){
//            return errorNotifier('no data')
//         }
//         // 
//         const total_session_time = filtered_students?.reduce((acc: any, currVal: any) => Number(acc) + Number(currVal?.duration), 0)

//         const alteredData = {
//             course_name: training_data[0].batch.course.course_name,
//             trainer_name: training_data[0].batch.user.first_name + training_data[0].batch.user.last_name,
//             city: training_data[0].batch.city.city_name,
//             branch: training_data[0].batch.branch.branch_name,
//             course_progress: calculatePercentage(total_session_time, Number(training_data[0]?.duration)),
//             training_id: training_data[0].id
//         }

//         if (session_data?.length === 0) {
//             return  {start_date: training_data[0]?.batch?.start_date, start_time: training_data[0]?.batch?.start_time , status: "no_classes"}
//         } else {
//             return {alteredData, session_data, status: "classes_started"}
//         }
    
//     } catch (error) {
//         return errorNotifier(error)
//     }
// }

// export const getPaidCourseProgress = async (trainingId: number) => {
//     try {
// //         // edited by frontend
// //         if(!training_data?.length){
// //            return errorNotifier('no data')
// //         }
// // // 

//         const { data: training_data, error: training_error }: any = await FetchData(TableNames.trainings, ["*", "batch:batches(id, duration, start_date, start_time,user:users(first_name, last_name), course:courses(course_name, duration), city:cities(city_name), branch:branches(branch_name), session:sessions(*))"]).eq("id", trainingId)
//                 if(!training_data?.length){
//                        return errorNotifier('no data')
//                     }


 

//         if (training_error) throw training_error

 

//         const { data: sessions, error: session_error } = await FetchData(TableNames.sessions, ["id", "attendees", "title", "details", "duration", "session_date", "recent_session", "batch:batches(id, course:courses(course_name,duration), city:cities(city_name), trainer:users(first_name, last_name), branch:branches(branch_name))"]).eq("batch_id", training_data[0]?.batch_id).order("session_date", { ascending: false })

//         console.log("sessions : ",sessions);

//         console.log("session_error : ",session_error);

        

//         const { data: cities, error: city_error } = await FetchData(TableNames.cities, ["id", "city_name"]).eq("id", training_data[0].city_id);

//         console.log("cities : ",cities,city_error,training_data[0].branch_id);

        

//         const { data: branches, error: branch_error } = await FetchData(TableNames.branches, ["id", "branch_name", "city"]).eq("id", training_data[0].branch_id);

//         console.log("branches : ",branches,branch_error);

 

 

//         if(!sessions?.length){

//             return {

//                 message:"No session created",

//                 data: {

//                     city: cities,

//                     branch : branches,

//                     // city: training_data[0].city_id,

//                     // branch: training_data[0].branch_id,

//                 }

//             }

//         }

 

//         const session_data = sessions?.map((session: any) => ({ ...session, is_present: session?.attendees?.includes(training_data[0].student_id) }));

 

//         const filtered_students = session_data?.filter((stu: any) => { return stu?.is_present === true })

        

//         const total_session_time = filtered_students?.reduce((acc: any, currVal: any) => Number(acc) + Number(currVal?.duration), 0)

 

//         const alteredData = {

//             course_name: training_data[0].batch.course.course_name,

//             trainer_name: training_data[0].batch.user.first_name + training_data[0].batch.user.last_name,

//             city: training_data[0].batch.city.city_name,

//             branch: training_data[0].batch.branch.branch_name,

//             // course_progress: calculatePercentage(total_session_time, Number(training_data[0]?.duration)),

//             course_progress: total_session_time && training_data[0]?.duration ? calculatePercentage(total_session_time, Number(training_data[0]?.duration)) : 0,

//             training_id: training_data[0].id

//         }

 

//         if (session_data?.length === 0) {

//             return  {start_date: training_data[0]?.batch?.start_date, start_time: training_data[0]?.batch?.start_time , status: "no_classes"}

//         } else {

//             return {alteredData, session_data, status: "classes_started"}

//         }

    

//     } catch (error) {


//         return errorNotifier(error)

//     }

// }

// export const getFreeCourseProgress = async (trainingId: number) => {
//     try {
//         const { data, error }: any = await FetchData(TableNames.trainings, ["student:student_id(name)", "course:course_id(course_name, videos)", "progress_by_module"]).eq("id", trainingId);
//         // edited by frontend
//         if(!data.length){
//             return errorNotifier('no data')
//         }
// // 
//             if(error) throw error
            
//             return { ...data[0], ...progressByModuleCalculation(data[0].course, data[0].progress_by_module)};
//     } catch (error) {
//         return errorNotifier(error)
//     }
// }


// export const getPaidCourseProgress = async (trainingId: number) => {
//     try {
//         const { data: training_data, error: training_error }: any = await FetchData(TableNames.trainings, ["*", "batch:batches(id, duration,batch_progress_in_hrs, start_date, start_time,user:users(first_name, last_name), course:courses(course_name, duration), city:cities(city_name), branch:branches(branch_name), session:sessions(*))"]).eq("id", trainingId)
//         if (training_error) throw training_error
//         // const { data: sessions, error: session_error } = await FetchData(TableNames.sessions, ["id", "attendees", "title", "details", "duration", "session_date", "recent_session", "batch:batches(id, course:courses(course_name,duration), city:cities(city_name), trainer:users(first_name, last_name), branch:branches(branch_name))"]).eq("batch_id", training_data[0]?.batch_id).order("session_date", { ascending: true })
//         const { data: sessions, error: session_error } = await FetchData(TableNames.sessions, ["id", "attendees", "title", "details", "duration", "session_date", "recent_session", "batch:batches(id, course:courses(course_name,duration), city:cities(city_name), trainer:users(first_name, last_name), branch:branches(branch_name))"]).eq("batch_id", training_data[0]?.batch_id).order("session_date", { ascending: false }).order("created_at", { ascending: false })

        

//         const { data: cities, error: city_error } = await FetchData(TableNames.cities, ["id", "city_name"]).eq("id", training_data[0].city_id);


        

//         const { data: branches, error: branch_error } = await FetchData(TableNames.branches, ["id", "branch_name", "city"]).eq("id", training_data[0].branch_id);


 

 

//         if(!sessions?.length){

//             return {

//                 message:"No session created",

//                 data: {

//                     city: cities,

//                     branch : branches,

//                     // city: training_data[0].city_id,

//                     // branch: training_data[0].branch_id,

//                 }

//             }

//         }

 

//         const session_data = sessions?.map((session: any) => ({ ...session, is_present: session?.attendees?.includes(training_data[0].student_id) }));

 

//         const filtered_students = session_data?.filter((stu: any) => { return stu?.is_present === true })

        

//         const total_session_time = filtered_students?.reduce((acc: any, currVal: any) => Number(acc) + Number(currVal?.duration), 0)

 

//         const alteredData = {

//             course_name: training_data[0].batch.course.course_name,

//             trainer_name: training_data[0].batch.user.first_name + training_data[0].batch.user.last_name,

//             city: training_data[0].batch.city.city_name,

//             branch: training_data[0].batch.branch.branch_name,

//             // course_progress: calculatePercentage(total_session_time, Number(training_data[0]?.duration)),

//             course_progress: calculatePercentage(Number(training_data[0].batch.batch_progress_in_hrs), Number(training_data[0].batch?.duration)),

//             training_id: training_data[0].id

//         }


        
//         if (session_data?.length === 0) {

//             return  {start_date: training_data[0]?.batch?.start_date, start_time: training_data[0]?.batch?.start_time , status: "no_classes"}

//         } else {

//             return {alteredData, session_data, status: "classes_started"}

//         }

    
//     } catch (error) {


//         return errorNotifier(error)

//     }

// }


export const studentPaymentDetailsBasedOnCourse = async (stud_id:any) => {
    try {
      const { data, error } = await FetchData(TableNames.trainings, [
        "course: courses(course_name)",
        "balance_due",
      ]).eq("student_id", stud_id);
        
      if (error) throw error;
      const courseDetails = data?.map((item: any) => {
        return {
          course_name: item?.course?.course_name,
          balance_due:
            item?.balance_due,
        };
      });
      return courseDetails;
    } catch (error) {
      return errorNotifier(error);
    }
}


export const getSessionsByStudentUid = async (studentUid: string) => {
    
    try {
        const { data: training, error }: any = await FetchData(TableNames.trainings, ["course_id", "batch:batches(id, in_progress)", "duration", "id"]).eq("student_id", studentUid)
        if (training.length > 0)
        {
            const filtered_trainings = training?.filter((train: any) => train?.batch?.in_progress === true)
            // edited by frontend 
            // if (filtered_trainings.length > 0){
            //     return {message: "Trainings not ", status: ""}
            // }
            const { data: student_sessions }: any = await FetchData(TableNames.sessions, ["id","end_time","attendees", "title", "details", "duration", "session_date", "recent_session", "batch:batches(id, course:courses(course_name,duration), city:cities(city_name), trainer:users(first_name, last_name), branch:branches(branch_name))"]).eq("batch_id", filtered_trainings[0]?.batch.id).order("session_date", { ascending: false })
            const {data:feedback,error:feedback_error} = await FetchData(TableNames.feedbacks,["id","session_id"]).eq("student_id",studentUid)
          
            // edited by frontend 
            if (student_sessions == null){
                return {message: "Not allocated to the batch", status: ""}
            }
            let session_id = feedback?.map((item:any)=>item?.session_id)
            const sessions = student_sessions?.map((session: any) => ({ ...session, is_present: session?.attendees?.includes(studentUid) ,feedbackGiven : session_id?.includes(session.id)}));
        

            const filtered_students = sessions?.filter((stu: any) => { return stu?.is_present === true })
            
            const total_session_time = filtered_students?.reduce((acc: any, currVal: any) => Number(acc) + Number(currVal?.duration), 0)
            const courseData = calculatePercentage(total_session_time, Number(training[0]?.duration));

        const session_data = sessions?.map((session: any) => ({ ...session, is_present: session?.attendees?.includes(filtered_trainings[0].student_id),feedbackGiven : session_id?.includes(session.id)}));

            const courseDetails = {
                course_name: sessions[0]?.batch?.course?.course_name,
                branch_name: sessions[0]?.batch?.branch?.branch_name,
                student_progress: Math.round(courseData),
                trainer_name: `${sessions[0]?.batch?.trainer?.first_name} ${sessions[0]?.batch?.trainer?.last_name}`,
                batch_id: sessions[0]?.batch?.id,
                training_id: filtered_trainings[0]?.id
            }

            if (error) throw error

            if (filtered_trainings.length > 0 && student_sessions.length === 0) {
                const { data: batch }: any = await FetchData(TableNames.batches, ["start_date", "start_time"]).eq("id", filtered_trainings[0].batch.id)
                return {start_date: batch[0]?.start_date, start_time: batch[0]?.start_time , status: "no_classes"}
            } else {
                return {sessions,session_data, courseDetails, status: "classes_started"}
            }
        } else {
            return {message: "Not allocated to the batch", status: "not_allocated"}
        }        
    } catch (error) {
        return errorNotifier(error)
    }
}


export const getPaidCourseProgress = async (trainingId: number,student_id:string) => {
    try {
        const { data: training_data, error: training_error }: any = await FetchData(TableNames.trainings,
            ["*", "batch:batches(id, duration, start_date, start_time,user:users(first_name, last_name), course:courses(course_name, duration), city:cities(city_name), branch:branches(branch_name), session:sessions(*))"]).eq("id", trainingId)
 
        if (training_error) throw training_error
        // const { data: sessions, error: session_error } = await FetchData(TableNames.sessions, ["id", "attendees", "title", "details", "duration", "session_date", "recent_session", "batch:batches(id, course:courses(course_name,duration), city:cities(city_name), trainer:users(first_name, last_name), branch:branches(branch_name))"]).eq("batch_id", training_data[0]?.batch_id).order("session_date", { ascending: true })
        const { data: sessions, error: session_error } = await FetchData(TableNames.sessions, ["id", "attendees", "title", "details", "duration", "session_date", "recent_session","end_time","batch:batches(id, course:courses(course_name,duration), city:cities(city_name), trainer:users(first_name, last_name), branch:branches(branch_name))"]).eq("batch_id", training_data[0]?.batch_id).order("session_date", { ascending: false }).order("created_at", { ascending: false })
        
        const {data:feedback,error:feedback_error} = await FetchData(TableNames.feedbacks,["id","session_id"]).eq("student_id",student_id)
        
        let session_id = feedback?.map((item:any)=>item?.session_id)

        const { data: cities, error: city_error } = await FetchData(TableNames.cities, ["id", "city_name"]).eq("id", training_data[0].city_id);
        
        const { data: branches, error: branch_error } = await FetchData(TableNames.branches, ["id", "branch_name", "city"]).eq("id", training_data[0].branch_id);
 
        if(!sessions?.length){
            return {
                message:"No session created",
                data: {
                    city: cities,
                    branch : branches,
                    // city: training_data[0].city_id,
                    // branch: training_data[0].branch_id,
                }
            }
        }
 
        const session_data = sessions?.map((session: any) => ({ ...session, is_present: session?.attendees?.includes(training_data[0].student_id),feedbackGiven : session_id?.includes(session.id)}));

        const filtered_students = session_data?.filter((stu: any) => { return stu?.is_present === true })
        
        const total_session_time = filtered_students?.reduce((acc: any, currVal: any) => Number(acc) + Number(currVal?.duration), 0)
        
        const alteredData = {
            course_name: training_data[0].batch.course.course_name,
            trainer_name: training_data[0].batch.user.first_name + training_data[0].batch.user.last_name,
            city: training_data[0].batch.city.city_name,
            branch: training_data[0].batch.branch.branch_name,
            // course_progress: calculatePercentage(total_session_time, Number(training_data[0]?.duration)),
            course_progress: (total_session_time && training_data[0]?.batch?.course?.duration)? calculatePercentage(total_session_time, training_data[0]?.batch.course.duration) : 0,
            training_id: training_data[0].id

        }
 console.log(total_session_time, training_data[0]?.batch?.course?.duration ,'jikkkkk')
        if (session_data?.length === 0) {
            return  {start_date: training_data[0]?.batch?.start_date, start_time: training_data[0]?.batch?.start_time , status: "no_classes"}
        } else {
            return {alteredData, session_data, status: "classes_started"}
        }
    
    } catch (error) {
        return errorNotifier(error)
    }
}



export const getFreeCourseProgress = async (trainingId: number) => {

    try {

        const { data, error }: any = await FetchData(TableNames.trainings, ["student:student_id(name)", "course:course_id(course_name, videos, duration)", "progress_by_module"]).eq("id", trainingId);
            if(error) throw error
            return { ...data[0], ...progressByModuleCalculation(data[0].course, data[0].progress_by_module)};
    } catch (error) {
        return errorNotifier(error)
    }

}



// for student
