import { useFormik } from "formik";
import { basicSchema } from "./formschema";
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import formBgbtm from '../../images/formBgbtm.png'
import formBgtop from '../../images/formBgtop.png'
import checkboxIcon from '../../images/checkboxIcon.svg'
import { useState, useEffect } from "react";
import { SUPABASE_CLIENT } from '../../Services/Supabase'
import { useSelector } from "react-redux";
import { selected_mobileNo } from '../../redux/useReducer'
import { createStudent, getStudentData, updateStudent } from "../../function/Student/SignUp/Student";
import { sendOTPToEmail,sendOTPService } from "../../Services/OTP";
import uncheckbox from '../../images/uncheckbox.svg'
// import { uuid } from '../../redux/useReducer'
import { get_uid } from "./Uuid";

const SignUp = () => {
  const { state } = useLocation()
  const [mobileNo] = useState(state)
  const getmobilData = useSelector(selected_mobileNo)
  // const [sessionData, setSessionData] = useState({}) as any
  const navigate = useNavigate()

  let signinDetail: any
  const mb =mobileNo.mobileNo
  const [mobileNumber, setMoileNumber] = useState(mb)
  const [signinMode, setSigninMode] = useState('')
  const [tickicon, setTickicon] = useState(uncheckbox)
  const[ checktick, setChecktick] =useState(false)
  const[ register, setregister] =useState(false)

  useEffect(() => {
    if (state.clickvalue == 'email') {
      setSigninMode('email')
    } else {   
      setSigninMode('google')
    formdata()
    }
  }, [])

 
  const formdata = async () => {
    values.firstname = mobileNo?.emailData?.given_name
    values.lastname = mobileNo?.emailData?.family_name
    values.email = mobileNo?.emailData?.email
    let model_name = mobileNo.mobileNo
    setMoileNumber(model_name)
  }

  const changetick = (value: any) => {
    if (value === '/static/media/uncheckbox.3923c9ab245ef8f9e52f9564e27d8ad0.svg') {
      setTickicon(checkboxIcon)
      setChecktick(true)
    } else {
      setTickicon(uncheckbox)
      setChecktick(false)
    }
  }

  const onSubmit = async () => {
    const uuid:any = localStorage.getItem('uid')
    await new Promise((resolve) => setTimeout(resolve, 1000));
      if (signinMode === 'google') {
          await updateStudent({ uid: uuid, name: values.firstname, email: values.email ,is_email_verified : true, type: 'App Student'})
          navigate('/beforeassessment')
        } 
        else if (signinMode === 'email') {
        // await sendOTPToEmail(uuid, (values.email))
        let student = await updateStudent({ uid: uuid, name: values.firstname, email: values.email,type:'App Student'})
        if(student){
          await sendOTPService(values.email,'email')
          navigate('/verifyemail', { state: { uid: uuid, name: values.firstname, email: values.email, mobileNo: mobileNumber } })
        }

      }
  };

  const inputstyle = 'bg-#FEFEFE-50 shadow-3xl w-[500px] border font-[roboto] font-normal border-gray-300 my-3 text-gray-900 text-2xl rounded-full  block p-2.5 pl-12 max-mobile:w-72 max-mobile:mx-auto max-mobile:text-[16px] max-mobile:my-3'

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
    },
    validationSchema: basicSchema,
    onSubmit,
  });
  return (
    <div className="bg-[url('../public/images/frontBg.png')] h-full max-monitor:h-full max-desktop:h-full max-laptop:h-full max-mobile:h-[100vh] max-mobile:overflow-hidden">
      <div className="flex justify-end">
        <img className="w-2/12" src={formBgtop} alt='bg img' />
      </div>
      <form  autoComplete="off"  onSubmit={handleSubmit} className="  grid justify-center max-desktop:mt-0 max-laptop:mt-5 max-mobile:mt-[40px]">
        <input
          value={values.firstname}
          onChange={handleChange}
          id="firstname"
          type="firstname"
          placeholder="First Name"
          onBlur={handleBlur}
          className={inputstyle}
        />
        {errors.firstname && touched.firstname && <p  className="error text-[red]">{errors.firstname}</p>}
        <input
          value={values.lastname}
          onChange={handleChange}
          id="lastname"
          type="lastname"
          placeholder="Last Name"
          onBlur={handleBlur}
          className={inputstyle}
        />
        {errors.lastname && touched.lastname && <p className="error text-[red]">{errors.lastname}</p>}

        <input
          value={values.email}
          onChange={handleChange}
          id="email"
          type="email"
          placeholder="E-Mail Address"
          onBlur={handleBlur}
          className={inputstyle}
          disabled = {signinMode == 'google'? true : false }
        />
        {errors.email && touched.email && <p className="error text-[red]">{errors.email}</p>}
        <input
          id="mobileNumber"
          type="string"
          placeholder="Mobile Number"
          value={`+91${mobileNumber}`}
          onBlur={handleBlur}
          className={inputstyle}
          disabled={true}
        />
        <div className="w-full my-5 flex items-center mx-14"><img className="w-[25px] h-[25px] cursor-pointer" src={tickicon} onClick={() => changetick(tickicon)} alt='checkbox' /> <p className=" ml-5 text-[25px] font-normal text-[#585858] max-mobile:text-[16px]">I accept all terms and conditions</p> </div>
       {checktick ? <button className="text-white text-center mt-5  ml-36 w-44  disabled:bg-[#f7a08bf5] ] enabled:bg-[#E92D00]  font-medium rounded-full text-[17px] px-5 py-2.5 mb-2 max-mobile:mx-auto" disabled={isSubmitting}   type="submit"> Register </button> : <button className="text-white text-center mt-5  ml-36 w-44  disabled:bg-[#f7a08bf5]  enabled:bg-[#f7a08bf5] font-medium rounded-full text-[17px] px-5 py-2.5 mb-2 max-mobile:mx-auto" disabled  type="submit"> Register </button> } 
      </form>

      <div className="flex max-mobile:mt-[40px] max-desktop:mt-0 max-laptop:mt-0">
        <img className="w-2/12" src={formBgbtm} alt='bg img' />
      </div>
    </div>
  );
};
export default SignUp;
