// // // // // // // // // // import React, { useEffect, useRef, useState, CSSProperties } from "react";
// // // // // // // // // // import Header from "../elements/header";
// // // // // // // // // // import viewallicon from "../../images/viewallicon.svg";
// // // // // // // // // // import Reasource from "./reasource";
// // // // // // // // // // import Jobopening from "./jobopening";
// // // // // // // // // // import DreamJob from "./dreamjob";
// // // // // // // // // // import { NavLink, Link, useNavigate, Routes } from "react-router-dom";

// // // // // // // // // // import { useSelector } from "react-redux";
// // // // // // // // // // import {
// // // // // // // // // //   courseDetails_Data,
// // // // // // // // // //   setcourseDetails_Data,
// // // // // // // // // //   setjobOpening_data,
// // // // // // // // // //   training_idData,
// // // // // // // // // // } from "../../redux/useReducer";
// // // // // // // // // // import White_lock from "../../images/White_lock.svg";
// // // // // // // // // // import Closepopup from "../../images/close-red-icon.svg";
// // // // // // // // // // import { useDispatch } from "react-redux";
// // // // // // // // // // import { jobOpening_data } from "../../redux/useReducer";
// // // // // // // // // // import { getSessionsByStudentUid, studentPaymentDetailsBasedOnCourse } from "../../function/Student/CourseProgree/CourseProgress";
// // // // // // // // // // import CourseProgress from "./courseProgress";
// // // // // // // // // // // import { uuid } from "../../redux/useReducer";
// // // // // // // // // // import { getCourseDetails, getCourses } from "../../function/Student/Profile/Profile";

// // // // // // // // // // import ClipLoader from "react-spinners/ClipLoader";
// // // // // // // // // // import Footer from "../elements/Footer";

// // // // // // // // // // interface PaymentSummary {
// // // // // // // // // //   course_name: string;
// // // // // // // // // //   total_fee: number;
// // // // // // // // // //   paid_amount: number;
// // // // // // // // // //   balance_due: number;
// // // // // // // // // // }

// // // // // // // // // // interface CourseDetails {
// // // // // // // // // //   payment_summary: PaymentSummary;
// // // // // // // // // //   id: string; // Add any other relevant fields
// // // // // // // // // // }

// // // // // // // // // // function Homepage() {
// // // // // // // // // //   const stud_uuid: any = localStorage.getItem("uid");
// // // // // // // // // //   const navigate = useNavigate();
// // // // // // // // // //   const dispatch = useDispatch();
// // // // // // // // // //   const [courseprogess, setCourseprogess] = useState(true);
// // // // // // // // // //   // const getmobilData = useSelector(setjobOpening_data);
// // // // // // // // // //   let [loading, setLoading] = useState(true);
// // // // // // // // // //   const [courseDetails, setCourseDetails] = useState<CourseDetails | null>(
// // // // // // // // // //     null
// // // // // // // // // //   );
// // // // // // // // // //   // let state = getmobilData.data

// // // // // // // // // //   const override: CSSProperties = {
// // // // // // // // // //     flex: 1,
// // // // // // // // // //     marginTop: 240,
// // // // // // // // // //     justifyContent: "center",
// // // // // // // // // //     alignItems: "center",
// // // // // // // // // //   };
// // // // // // // // // //   const data = async () => {
// // // // // // // // // //     setLoading(true);
// // // // // // // // // //     const course_data = await getCourses(stud_uuid);
// // // // // // // // // //     Coursevalue(course_data);
// // // // // // // // // //     setLoading(false);
// // // // // // // // // //     // console.log(course_data,'course_data')
// // // // // // // // // //     if (stud_uuid === null) {
// // // // // // // // // //       navigate("/");
// // // // // // // // // //     }

// // // // // // // // // //     setCourseDetails(course_data);
// // // // // // // // // //   };

// // // // // // // // // //   const Coursevalue = (props: any) => {
// // // // // // // // // //     dispatch(
// // // // // // // // // //       training_idData({
// // // // // // // // // //         data: props.id,
// // // // // // // // // //       })
// // // // // // // // // //     );
// // // // // // // // // //   };

// // // // // // // // // //   // const onClickhandles = () => {
// // // // // // // // // //   //     dispatch(jobOpening_data({
// // // // // // // // // //   //         data: false
// // // // // // // // // //   //     }));
// // // // // // // // // //   // }

// // // // // // // // // //   useEffect(() => {
// // // // // // // // // //     document.documentElement.scrollTo({
// // // // // // // // // //       top: 0,
// // // // // // // // // //       left: 0,
// // // // // // // // // //     });
// // // // // // // // // //     data();
// // // // // // // // // //   }, []);

// // // // // // // // // //   useEffect(() => {
// // // // // // // // // //     (async()=>{
// // // // // // // // // //       const response: any = await studentPaymentDetailsBasedOnCourse(stud_uuid)
// // // // // // // // // //       console.log(response, "response")
// // // // // // // // // //       console.log(response[0]?.course_name)
// // // // // // // // // //       console.log(response[0]?.balance_due)
// // // // // // // // // //     })()
// // // // // // // // // //   }, []);

// // // // // // // // // //   // const uuid: any = localStorage.getItem("uid");

// // // // // // // // // //   // const testfn = async () => {
// // // // // // // // // //   //   console.log(uuid);
// // // // // // // // // //   //   const res: any = await getSessionsByStudentUid(uuid);
// // // // // // // // // //   //   console.log("result", res);
// // // // // // // // // //   //   if (res && 'courseDetails' in res) {
// // // // // // // // // //   //     console.log('coursedetails', res.courseDetails?.batch_id);
// // // // // // // // // //   //     const pay :any = await getCourseDetails(res.courseDetails?.training_id, res.courseDetails?.batch_id, uuid);
// // // // // // // // // //   //     console.log('coursename', pay?.payment_summary?.course_name);
// // // // // // // // // //   //     console.log('due', pay?.payment_summary?.balance_due);

// // // // // // // // // //   //     setCourseDetails(pay);
// // // // // // // // // //   //   }
// // // // // // // // // //   // };
  

// // // // // // // // // //   return (
// // // // // // // // // //     <div>
// // // // // // // // // //       {/* {!state ? <div> */}
// // // // // // // // // //       {loading == false ? (
// // // // // // // // // //         <>
// // // // // // // // // //           {/* <Header /> */}
// // // // // // // // // //           <p className="text-[45px] max-laptop:text-[40px] max-tablet:text-[30px] max-mobile:text-[18px] font-normal font-[roboto] text-left text-[#707070] ml-5 mt-10">
// // // // // // // // // //             Stay Ahead with{" "}
// // // // // // // // // //             <span className="font-bold text-[#000]">FITA Academy</span>{" "}
// // // // // // // // // //           </p>
// // // // // // // // // //             {courseDetails?.payment_summary && (
// // // // // // // // // //             <div className="bg-white shadow-md rounded-lg p-4 mt-6 mx-5 max-w-sm text-left">
// // // // // // // // // //               <h2 className="text-lg font-semibold text-gray-800">
// // // // // // // // // //                 Course Name : {response[0]?.course_name}
// // // // // // // // // //               </h2>
// // // // // // // // // //               <p className="text-sm text-gray-600">
// // // // // // // // // //                 Balance Due: &#8377;{response[0]?.balance_due}
// // // // // // // // // //               </p>
// // // // // // // // // //             </div>
// // // // // // // // // //           )}

// // // // // // // // // //           <DreamJob />
// // // // // // // // // //           {courseprogess ? (
// // // // // // // // // //             <div className=" bg-[#F9FCFF] text-left">
// // // // // // // // // //               <CourseProgress />
// // // // // // // // // //             </div>
// // // // // // // // // //           ) : (
// // // // // // // // // //             <div> </div>
// // // // // // // // // //           )}
// // // // // // // // // //           <Jobopening />
// // // // // // // // // //           <Reasource />
// // // // // // // // // //         </>
// // // // // // // // // //       ) : (
// // // // // // // // // //         <ClipLoader color="#036AD1" loading={loading} cssOverride={override} />
// // // // // // // // // //       )}
// // // // // // // // // //       <Footer />
// // // // // // // // // //       {/* </div>
// // // // // // // // // //                 : <div className=' flex h-[100vh] bg-[#F9FCFF] items-center align-middle '>
// // // // // // // // // //                     <div className=' align-middle mx-auto text-center relative  justify-center p-6 bg-white border border-gray-200 rounded-lg shadow w-2/6'>
// // // // // // // // // //                         <img className={` cursor-pointer rounded-full p-1 right-3  top-3 absolute mb-5 w-8 `} src={Closepopup} onClick={() => onClickhandles()} alt="closeimage"></img>
// // // // // // // // // //                         <div className='flex  py-10'>
// // // // // // // // // //                             <img className='w-[80px] h-[110px]' src={White_lock} alt='icon' />
// // // // // // // // // //                             <p className='w-[270px]  text-[18px] text-[#000] text-left pl-5 max-monitor:mr-5'>Jobs section will be enabled after you complete the Balance payment and completing at least 90% Course</p>
// // // // // // // // // //                         </div>
// // // // // // // // // //                     </div>
// // // // // // // // // //                 </div>
// // // // // // // // // //             } */}
// // // // // // // // // //     </div>
// // // // // // // // // //   );
// // // // // // // // // // }

// // // // // // // // // // export default Homepage;


// // // // // // // // // // import React, { useEffect, useState, CSSProperties } from "react";
// // // // // // // // // // import DreamJob from "./dreamjob";
// // // // // // // // // // import ClipLoader from "react-spinners/ClipLoader";
// // // // // // // // // // import Footer from "../elements/Footer";
// // // // // // // // // // import { useNavigate } from "react-router-dom";
// // // // // // // // // // import { useDispatch } from "react-redux";
// // // // // // // // // // import { training_idData } from "../../redux/useReducer";
// // // // // // // // // // import { getSessionsByStudentUid } from "../../function/Student/CourseProgree/CourseProgress";
// // // // // // // // // // import CourseProgress from "./courseProgress";
// // // // // // // // // // import { getCourseDetails, getCourses } from "../../function/Student/Profile/Profile";

// // // // // // // // // // interface PaymentSummary {
// // // // // // // // // //   course_name: string;
// // // // // // // // // //   total_fee: number;
// // // // // // // // // //   paid_amount: number;
// // // // // // // // // //   balance_due: number;
// // // // // // // // // // }

// // // // // // // // // // interface CourseDetails {
// // // // // // // // // //   payment_summary: PaymentSummary;
// // // // // // // // // //   id: string;
// // // // // // // // // // }

// // // // // // // // // // function Homepage() {
// // // // // // // // // //   const stud_uuid: any = localStorage.getItem("uid");
// // // // // // // // // //   const navigate = useNavigate();
// // // // // // // // // //   const dispatch = useDispatch();
// // // // // // // // // //   const [courseprogess, setCourseprogess] = useState(true);
// // // // // // // // // //   let [loading, setLoading] = useState(true);
// // // // // // // // // //   const [courseDetails, setCourseDetails] = useState<CourseDetails | null>(null);

// // // // // // // // // //   const override: CSSProperties = {
// // // // // // // // // //     flex: 1,
// // // // // // // // // //     marginTop: 240,
// // // // // // // // // //     justifyContent: "center",
// // // // // // // // // //     alignItems: "center",
// // // // // // // // // //   };

// // // // // // // // // //   const data = async () => {
// // // // // // // // // //     setLoading(true);
// // // // // // // // // //     const course_data = await getCourses(stud_uuid);
// // // // // // // // // //     Coursevalue(course_data);
// // // // // // // // // //     setLoading(false);

// // // // // // // // // //     if (stud_uuid === null) {
// // // // // // // // // //       navigate("/");
// // // // // // // // // //     }

// // // // // // // // // //     setCourseDetails(course_data);
// // // // // // // // // //   };

// // // // // // // // // //   const Coursevalue = (props: any) => {
// // // // // // // // // //     dispatch(
// // // // // // // // // //       training_idData({
// // // // // // // // // //         data: props.id,
// // // // // // // // // //       })
// // // // // // // // // //     );
// // // // // // // // // //   };

// // // // // // // // // //   useEffect(() => {
// // // // // // // // // //     document.documentElement.scrollTo({
// // // // // // // // // //       top: 0,
// // // // // // // // // //       left: 0,
// // // // // // // // // //     });
// // // // // // // // // //     data();
// // // // // // // // // //   }, []);

// // // // // // // // // //   useEffect(() => {
// // // // // // // // // //     testfn();
// // // // // // // // // //   }, []);

// // // // // // // // // //   const uuid: any = localStorage.getItem("uid");

// // // // // // // // // //   const testfn = async () => {
// // // // // // // // // //     console.log(uuid);
// // // // // // // // // //     const res: any = await getSessionsByStudentUid(uuid);
// // // // // // // // // //     console.log("result", res);
// // // // // // // // // //     if (res && "courseDetails" in res) {
// // // // // // // // // //       console.log("coursedetails", res.courseDetails?.batch_id);
// // // // // // // // // //       const pay: any = await getCourseDetails(res.courseDetails?.training_id, res.courseDetails?.batch_id, uuid);
// // // // // // // // // //       console.log("coursename", pay?.payment_summary?.course_name);
// // // // // // // // // //       console.log("due", pay?.payment_summary?.balance_due);

// // // // // // // // // //       // Set course details with payment summary
// // // // // // // // // //       setCourseDetails(pay);
// // // // // // // // // //     }
// // // // // // // // // //   };

// // // // // // // // // //   return (
// // // // // // // // // //     <div>
// // // // // // // // // //       {loading === false ? (
// // // // // // // // // //         <>
// // // // // // // // // //           <p className="text-[45px] max-laptop:text-[40px] max-tablet:text-[30px] max-mobile:text-[18px] font-normal font-[roboto] text-left text-[#707070] ml-5 mt-10">
// // // // // // // // // //             Stay Ahead with{" "}
// // // // // // // // // //             <span className="font-bold text-[#000]">FITA Academy</span>
// // // // // // // // // //           </p>

// // // // // // // // // //           {/* Display Course Name and Balance Due in a Card */}
// // // // // // // // // //           {courseDetails?.payment_summary && (
// // // // // // // // // //             <div className="bg-white shadow-md rounded-lg p-4 mt-6 mx-5 max-w-sm">
// // // // // // // // // //               <h2 className="text-lg font-semibold text-gray-800">
// // // // // // // // // //                 Course Name : {courseDetails.payment_summary.course_name}
// // // // // // // // // //               </h2>
// // // // // // // // // //               <p className="text-sm text-gray-600">
// // // // // // // // // //                 Balance Due: &#8377;{courseDetails.payment_summary.balance_due}
// // // // // // // // // //               </p>
// // // // // // // // // //             </div>
// // // // // // // // // //           )}

// // // // // // // // // //           <DreamJob />
// // // // // // // // // //           {courseprogess ? (
// // // // // // // // // //             <div className=" bg-[#F9FCFF] text-left">
// // // // // // // // // //               <CourseProgress />
// // // // // // // // // //             </div>
// // // // // // // // // //           ) : (
// // // // // // // // // //             <div> </div>
// // // // // // // // // //           )}
// // // // // // // // // //           <Footer />
// // // // // // // // // //         </>
// // // // // // // // // //       ) : (
// // // // // // // // // //         <ClipLoader color="#036AD1" loading={loading} cssOverride={override} />
// // // // // // // // // //       )}
// // // // // // // // // //     </div>
// // // // // // // // // //   );
// // // // // // // // // // }

// // // // // // // // // // export default Homepage;



// // // // // import React, { useEffect, useState, CSSProperties } from "react";
// // // // // import Header from "../elements/header";
// // // // // import viewallicon from "../../images/viewallicon.svg";
// // // // // import Reasource from "./reasource";
// // // // // import Jobopening from "./jobopening";
// // // // // import DreamJob from "./dreamjob";
// // // // // import { useNavigate } from "react-router-dom";

// // // // // import { useDispatch } from "react-redux";
// // // // // import {
// // // // //   training_idData,
// // // // // } from "../../redux/useReducer";
// // // // // import White_lock from "../../images/White_lock.svg";
// // // // // import Closepopup from "../../images/close-red-icon.svg";
// // // // // import { studentPaymentDetailsBasedOnCourse } from "../../function/Student/CourseProgree/CourseProgress";
// // // // // import CourseProgress from "./courseProgress";
// // // // // import { getCourses } from "../../function/Student/Profile/Profile";

// // // // // import ClipLoader from "react-spinners/ClipLoader";
// // // // // import Footer from "../elements/Footer";

// // // // // interface PaymentSummary {
// // // // //   course_name: string;
// // // // //   total_fee: number;
// // // // //   paid_amount: number;
// // // // //   balance_due: number;
// // // // // }

// // // // // interface CourseDetails {
// // // // //   payment_summary: PaymentSummary;
// // // // //   id: string; // Add any other relevant fields
// // // // // }

// // // // // function Homepage() {
// // // // //   const stud_uuid: any = localStorage.getItem("uid");
// // // // //   const navigate = useNavigate();
// // // // //   const dispatch = useDispatch();
// // // // //   const [courseprogess, setCourseprogess] = useState(true);
// // // // //   let [loading, setLoading] = useState(true);
// // // // //   const [courseDetails, setCourseDetails] = useState<CourseDetails | null>(null);

// // // // //   const override: CSSProperties = {
// // // // //     flex: 1,
// // // // //     marginTop: 240,
// // // // //     justifyContent: "center",
// // // // //     alignItems: "center",     
// // // // //   };

// // // // //   const data = async () => {
// // // // //     setLoading(true);
// // // // //     const course_data = await getCourses(stud_uuid);
// // // // //     Coursevalue(course_data);
// // // // //     setLoading(false);
// // // // //     if (stud_uuid === null) {
// // // // //       navigate("/");
// // // // //     }
// // // // //     setCourseDetails(course_data);
// // // // //   };

// // // // //   const Coursevalue = (props: any) => {
// // // // //     dispatch(
// // // // //       training_idData({
// // // // //         data: props.id,
// // // // //       })
// // // // //     );
// // // // //   };

// // // // //   useEffect(() => {
// // // // //     document.documentElement.scrollTo({
// // // // //       top: 0,
// // // // //       left: 0,
// // // // //     });
// // // // //     data();
// // // // //   }, []);

// // // // //   useEffect(() => {
// // // // //     (async () => {
// // // // //       const response: any = await studentPaymentDetailsBasedOnCourse(stud_uuid);
// // // // //       console.log(response, "response");
// // // // //       if (response && response.length > 0) {
// // // // //         const paymentSummary = response[0];
// // // // //         setCourseDetails(prevState => ({
// // // // //           ...prevState ?? { id: "" }, // Provide default value for id if prevState is null
// // // // //           payment_summary: paymentSummary
// // // // //         }));
// // // // //       }
// // // // //     })();
// // // // //   }, []);

// // // // //   return (
// // // // //     <div>
// // // // //       {loading == false ? (
// // // // //         <>
// // // // //         <Header/>
// // // // //           <p className="text-[45px] max-laptop:text-[40px] max-tablet:text-[30px] max-mobile:text-[18px] font-normal font-[roboto] text-left text-[#707070] ml-5 mt-10">
// // // // //             Stay Ahead with{" "}
// // // // //             <span className="font-bold text-[#000]">FITA Academy</span>{" "}
// // // // //           </p>
// // // // //           <div className={`border px-4 py-3 rounded relative bg-yellow-100 border-yellow-400 text-yellow-700 mb-4`} role="alert">
// // // // //       <span className="block sm:inline">Hello World !</span>
// // // // //     </div>
// // // // //           {courseDetails?.payment_summary && (
// // // // //             <div className="bg-white shadow-md rounded-lg p-4 mt-6 mx-5 max-w-sm text-left">
// // // // //               <h2 className="text-lg font-semibold text-gray-800">
// // // // //                 Course Name : {courseDetails.payment_summary.course_name}
// // // // //               </h2>
// // // // //               <p className="text-sm text-gray-600">
// // // // //                 Balance Due: &#8377;{courseDetails.payment_summary.balance_due.toFixed(2)}
// // // // //               </p>
// // // // //             </div>
// // // // //           )}

// // // // //           <DreamJob />
// // // // //           {courseprogess ? (
// // // // //             <div className=" bg-[#F9FCFF] text-left">
// // // // //               <CourseProgress />
// // // // //             </div>
// // // // //           ) : (
// // // // //             <div> </div>
// // // // //           )}
// // // // //           <Jobopening />
// // // // //           <Reasource />
// // // // //         </>
// // // // //       ) : (
// // // // //         <ClipLoader color="#036AD1" loading={loading} cssOverride={override} />
// // // // //       )}
// // // // //       <Footer />
// // // // //     </div>
// // // // //   );
// // // // // }

// // // // // export default Homepage;










// // // // // import React, { useEffect, useState, CSSProperties } from "react";
// // // // // import Header from "../elements/header";
// // // // // import Reasource from "./reasource";
// // // // // import Jobopening from "./jobopening";
// // // // // import DreamJob from "./dreamjob";
// // // // // import { useNavigate } from "react-router-dom";
// // // // // import { useDispatch } from "react-redux";
// // // // // import { training_idData } from "../../redux/useReducer";
// // // // // import { studentPaymentDetailsBasedOnCourse } from "../../function/Student/CourseProgree/CourseProgress";
// // // // // import CourseProgress from "./courseProgress";
// // // // // import { getCourses } from "../../function/Student/Profile/Profile";
// // // // // import ClipLoader from "react-spinners/ClipLoader";
// // // // // import Footer from "../elements/Footer";

// // // // // interface PaymentSummary {
// // // // //   course_name: string;
// // // // //   total_fee: number;
// // // // //   paid_amount: number;
// // // // //   balance_due: number;
// // // // // }

// // // // // interface CourseDetails {
// // // // //   payment_summary: PaymentSummary[];
// // // // //   id: string;
// // // // // }

// // // // // function Homepage() {
// // // // //   const stud_uuid: any = localStorage.getItem("uid");
// // // // //   const navigate = useNavigate();
// // // // //   const dispatch = useDispatch();
// // // // //   const [courseprogess, setCourseprogess] = useState(true);
// // // // //   let [loading, setLoading] = useState(true);
// // // // //   const [courseDetails, setCourseDetails] = useState<CourseDetails | null>(null);

// // // // //   const override: CSSProperties = {
// // // // //     flex: 1,
// // // // //     marginTop: 240,
// // // // //     justifyContent: "center",
// // // // //     alignItems: "center",
// // // // //   };

// // // // //   const data = async () => {
// // // // //     setLoading(true);
// // // // //     const course_data = await getCourses(stud_uuid);
// // // // //     Coursevalue(course_data);
// // // // //     setLoading(false);
// // // // //     if (stud_uuid === null) {
// // // // //       navigate("/");
// // // // //     }
// // // // //     setCourseDetails(course_data);
// // // // //   };

// // // // //   const Coursevalue = (props: any) => {
// // // // //     dispatch(
// // // // //       training_idData({
// // // // //         data: props.id,
// // // // //       })
// // // // //     );
// // // // //   };

// // // // //   useEffect(() => {
// // // // //     document.documentElement.scrollTo({
// // // // //       top: 0,
// // // // //       left: 0,
// // // // //     });
// // // // //     data();
// // // // //   }, []);

// // // // //   useEffect(() => {
// // // // //     (async () => {
// // // // //       const response: any = await studentPaymentDetailsBasedOnCourse(stud_uuid);
// // // // //       if (response && response.length > 0) {
// // // // //         setCourseDetails(prevState => ({
// // // // //           ...prevState ?? { id: "" },
// // // // //           payment_summary: response.map((course: any) => ({
// // // // //             course_name: course.course_name,
// // // // //             total_fee: course.total_fee,
// // // // //             paid_amount: course.paid_amount,
// // // // //             balance_due: course.balance_due,
// // // // //           })),
// // // // //         }));
// // // // //       }
// // // // //     })();
// // // // //   }, []);

// // // // //   return (
// // // // //     <div>
// // // // //       {loading == false ? (
// // // // //         <>
// // // // //           <Header />
// // // // //           <p className="text-[45px] max-laptop:text-[40px] max-tablet:text-[30px] max-mobile:text-[18px] font-normal font-[roboto] text-left text-[#707070] ml-5 mt-10">
// // // // //             Stay Ahead with{" "}
// // // // //             <span className="font-bold text-[#000]">FITA Academy</span>{" "}
// // // // //           </p>
// // // // //           {courseDetails?.payment_summary && courseDetails.payment_summary.length > 0 && (
// // // // //             <div
// // // // //               className="border px-4 py-3 rounded relative bg-yellow-100 border-yellow-400 text-yellow-700 mb-4 overflow-x-auto whitespace-nowrap"
// // // // //               role="alert"
// // // // //             >
// // // // //               {courseDetails.payment_summary.map((summary, index) => (
// // // // //                 <div key={index} className="inline-block mr-4">
// // // // //                   <span className="font-semibold">
// // // // //                     Course Name: {summary.course_name}
// // // // //                   </span>{" "}
// // // // //                   - Balance Due: &#8377;{summary.balance_due.toFixed(2)}
// // // // //                 </div>
// // // // //               ))}
// // // // //             </div>
// // // // //           )}
// // // // //           <DreamJob />
// // // // //           {courseprogess ? (
// // // // //             <div className=" bg-[#F9FCFF] text-left">
// // // // //               <CourseProgress />
// // // // //             </div>
// // // // //           ) : (
// // // // //             <div> </div>
// // // // //           )}
// // // // //           <Jobopening />
// // // // //           <Reasource />
// // // // //         </>
// // // // //       ) : (
// // // // //         <ClipLoader color="#036AD1" loading={loading} cssOverride={override} />
// // // // //       )}
// // // // //       <Footer />
// // // // //     </div>
// // // // //   );
// // // // // }

// // // // // export default Homepage;



// // // // // import React, { useEffect, useState, CSSProperties } from "react";
// // // // // import Header from "../elements/header";
// // // // // import Reasource from "./reasource";
// // // // // import Jobopening from "./jobopening";
// // // // // import DreamJob from "./dreamjob";
// // // // // import { useNavigate } from "react-router-dom";
// // // // // import { useDispatch } from "react-redux";
// // // // // import { training_idData } from "../../redux/useReducer";
// // // // // import { studentPaymentDetailsBasedOnCourse } from "../../function/Student/CourseProgree/CourseProgress";
// // // // // import CourseProgress from "./courseProgress";
// // // // // import { getCourses } from "../../function/Student/Profile/Profile";
// // // // // import ClipLoader from "react-spinners/ClipLoader";
// // // // // import Footer from "../elements/Footer";

// // // // // interface PaymentSummary {
// // // // //   course_name: string;
// // // // //   total_fee: number;
// // // // //   paid_amount: number;
// // // // //   balance_due: number;
// // // // // }

// // // // // interface CourseDetails {
// // // // //   payment_summary: PaymentSummary[];
// // // // //   id: string;
// // // // // }

// // // // // function Homepage() {
// // // // //   const stud_uuid: any = localStorage.getItem("uid");
// // // // //   const navigate = useNavigate();
// // // // //   const dispatch = useDispatch();
// // // // //   const [courseprogess, setCourseprogess] = useState(true);
// // // // //   let [loading, setLoading] = useState(true);
// // // // //   const [courseDetails, setCourseDetails] = useState<CourseDetails | null>(null);

// // // // //   const override: CSSProperties = {
// // // // //     flex: 1,
// // // // //     marginTop: 240,
// // // // //     justifyContent: "center",
// // // // //     alignItems: "center",
// // // // //   };

// // // // //   const data = async () => {
// // // // //     setLoading(true);
// // // // //     const course_data = await getCourses(stud_uuid);
// // // // //     Coursevalue(course_data);
// // // // //     setLoading(false);
// // // // //     if (stud_uuid === null) {
// // // // //       navigate("/");
// // // // //     }
// // // // //     setCourseDetails(course_data);
// // // // //   };

// // // // //   const Coursevalue = (props: any) => {
// // // // //     dispatch(
// // // // //       training_idData({
// // // // //         data: props.id,
// // // // //       })
// // // // //     );
// // // // //   };

// // // // //   useEffect(() => {
// // // // //     document.documentElement.scrollTo({
// // // // //       top: 0,
// // // // //       left: 0,
// // // // //     });
// // // // //     data();
// // // // //   }, []);

// // // // //   useEffect(() => {
// // // // //     (async () => {
// // // // //       const response: any = await studentPaymentDetailsBasedOnCourse(stud_uuid);
// // // // //       if (response && response.length > 0) {
// // // // //         setCourseDetails(prevState => ({
// // // // //           ...prevState ?? { id: "" },
// // // // //           payment_summary: response.map((course: any) => ({
// // // // //             course_name: course.course_name,
// // // // //             total_fee: course.total_fee,
// // // // //             paid_amount: course.paid_amount,
// // // // //             balance_due: course.balance_due,
// // // // //           })),
// // // // //         }));
// // // // //       }
// // // // //     })();
// // // // //   }, []);

// // // // //   return (
// // // // //     <div>
// // // // //       {loading == false ? (
// // // // //         <>
// // // // //           <Header />
// // // // //           <p className="text-[45px] max-laptop:text-[40px] max-tablet:text-[30px] max-mobile:text-[18px] font-normal font-[roboto] text-left text-[#707070] ml-5 mt-10">
// // // // //             Stay Ahead with{" "}
// // // // //             <span className="font-bold text-[#000]">FITA Academy</span>{" "}
// // // // //           </p>
// // // // //           {courseDetails?.payment_summary && courseDetails.payment_summary.length > 0 && (
// // // // //             <div
// // // // //               className="border px-4 py-1 rounded relative bg-yellow-100 border-yellow-400 text-yellow-700 mb-4 overflow-x-auto whitespace-nowrap flex items-center"
// // // // //               role="alert"
// // // // //             >
// // // // //               {/* Large Danger Icon */}
// // // // //               <span className="text-3xl text-red-600 mr-4">&#9888;</span>
// // // // //               <div>
// // // // //                 {courseDetails.payment_summary.map((summary, index) => (
// // // // //                   <div key={index} className="inline-block mr-4">
// // // // //                     <div className="flex flex-col text-left">
// // // // //                       <span className="font-semibold">
// // // // //                         Course Name: {summary.course_name}
// // // // //                       </span>
// // // // //                       <span className="text-sm text-gray-600">
// // // // //                         Balance Due: &#8377; {summary.balance_due.toFixed(2)}
// // // // //                       </span>
// // // // //                     </div>
// // // // //                   </div>
// // // // //                 ))}
// // // // //               </div>
// // // // //             </div>
// // // // //           )}
// // // // //           <DreamJob />
// // // // //           {courseprogess ? (
// // // // //             <div className="bg-[#F9FCFF] text-left">
// // // // //               <CourseProgress />
// // // // //             </div>
// // // // //           ) : (
// // // // //             <div> </div>
// // // // //           )}
// // // // //           <Jobopening />
// // // // //           <Reasource />
// // // // //         </>
// // // // //       ) : (
// // // // //         <ClipLoader color="#036AD1" loading={loading} cssOverride={override} />
// // // // //       )}
// // // // //       <Footer />
// // // // //     </div>
// // // // //   );
// // // // // }

// // // // // export default Homepage;





// // // // import React, { useEffect, useState, CSSProperties } from "react";
// // // // import Header from "../elements/header";
// // // // import Reasource from "./reasource";
// // // // import Jobopening from "./jobopening";
// // // // import DreamJob from "./dreamjob";
// // // // import { useNavigate } from "react-router-dom";
// // // // import { useDispatch } from "react-redux";
// // // // import { training_idData } from "../../redux/useReducer";
// // // // import { studentPaymentDetailsBasedOnCourse } from "../../function/Student/CourseProgree/CourseProgress";
// // // // import CourseProgress from "./courseProgress";
// // // // import { getCourses } from "../../function/Student/Profile/Profile";
// // // // import ClipLoader from "react-spinners/ClipLoader";
// // // // import Footer from "../elements/Footer";

// // // // interface PaymentSummary {
// // // //   course_name: string;
// // // //   total_fee: number;
// // // //   paid_amount: number;
// // // //   balance_due: number;
// // // // }

// // // // interface CourseDetails {
// // // //   payment_summary: PaymentSummary[];
// // // //   id: string;
// // // // }

// // // // function Homepage() {
// // // //   const stud_uuid: any = localStorage.getItem("uid");
// // // //   const navigate = useNavigate();
// // // //   const dispatch = useDispatch();
// // // //   const [courseprogess, setCourseprogess] = useState(true);
// // // //   const [loading, setLoading] = useState(true);
// // // //   const [courseDetails, setCourseDetails] = useState<CourseDetails | null>(null);

// // // //   const override: CSSProperties = {
// // // //     flex: 1,
// // // //     marginTop: 240,
// // // //     justifyContent: "center",
// // // //     alignItems: "center",
// // // //   };

// // // //   const fetchData = async () => {
// // // //     setLoading(true);
// // // //     try {
// // // //       if (stud_uuid) {
// // // //         const course_data = await getCourses(stud_uuid);
// // // //         setCourseDetails(course_data);
// // // //         Coursevalue(course_data);

// // // //         const response = await studentPaymentDetailsBasedOnCourse(stud_uuid);

// // // //         if (Array.isArray(response) && response.length > 0) {
// // // //           setCourseDetails(prevState => ({
// // // //             ...prevState ?? { id: "" },
// // // //             payment_summary: response.map((course: any) => ({
// // // //               course_name: course.course_name,
// // // //               total_fee: course.total_fee,
// // // //               paid_amount: course.paid_amount,
// // // //               balance_due: course.balance_due,
// // // //             })),
// // // //           }));
// // // //         }
// // // //       } else {
// // // //         navigate("/");
// // // //       }
// // // //     } catch (error) {
// // // //       console.error("Error fetching data:", error);
// // // //     } finally {
// // // //       setLoading(false);
// // // //     }
// // // //   };

// // // //   const Coursevalue = (props: any) => {
// // // //     dispatch(
// // // //       training_idData({
// // // //         data: props.id,
// // // //       })
// // // //     );
// // // //   };

// // // //   useEffect(() => {
// // // //     fetchData();
// // // //   }, [stud_uuid]);

// // // //   return (
// // // //     <div>
// // // //       {!loading ? (
// // // //         <>
// // // //           <Header />
// // // //           <p className="text-[45px] max-laptop:text-[40px] max-tablet:text-[30px] max-mobile:text-[18px] font-normal font-[roboto] text-left text-[#707070] ml-5 mt-10">
// // // //             Stay Ahead with{" "}
// // // //             <span className="font-bold text-[#000]">FITA Academy</span>{" "}
// // // //           </p>
// // // //           {courseDetails?.payment_summary && courseDetails.payment_summary.length > 0 && (
// // // //             <div className="px-5 py-4 border border-blue-700">
// // // //               <div className="flex flex-wrap border border-red-700">
// // // //                 {courseDetails.payment_summary.map((summary, index) => (
// // // //                   <div
// // // //                     key={index}
// // // //                     className="flex items-center bg-white w-[430px] p-4 text-left"
// // // //                   >
// // // //                     <div className="flex-shrink-0 w-2 bg-red-600 h-full rounded-l-md" />
// // // //                     <div className="flex-1 ml-4">
// // // //                       <h1 className="font-medium text-gray-900">
// // // //                         <span>Course Name:</span> {summary.course_name}
// // // //                       </h1>
// // // //                       <p className="text-gray-500"><span>Balance Due:</span> &#8377; {summary.balance_due.toFixed(2)}</p>
// // // //                     </div>
// // // //                   </div>
// // // //                 ))}
// // // //               </div>
// // // //             </div>
// // // //           )}
// // // //           <DreamJob />
// // // //           {courseprogess ? (
// // // //             <div className="bg-[#F9FCFF] text-left">
// // // //               <CourseProgress />
// // // //             </div>
// // // //           ) : (
// // // //             <div> </div>
// // // //           )}
// // // //           <Jobopening />
// // // //           <Reasource />
// // // //         </>
// // // //       ) : (
// // // //         <ClipLoader color="#036AD1" loading={loading} cssOverride={override} />
// // // //       )}
// // // //       <Footer />
// // // //     </div>
// // // //   );
// // // // }

// // // // export default Homepage;



// // // import React, { useEffect, useState, CSSProperties } from "react";
// // // import Header from "../elements/header";
// // // import Reasource from "./reasource";
// // // import Jobopening from "./jobopening";
// // // import DreamJob from "./dreamjob";
// // // import { useNavigate } from "react-router-dom";
// // // import { useDispatch } from "react-redux";
// // // import { training_idData } from "../../redux/useReducer";
// // // import { studentPaymentDetailsBasedOnCourse } from "../../function/Student/CourseProgree/CourseProgress";
// // // import CourseProgress from "./courseProgress";
// // // import { getCourses } from "../../function/Student/Profile/Profile";
// // // import ClipLoader from "react-spinners/ClipLoader";
// // // import Footer from "../elements/Footer";

// // // interface PaymentSummary {
// // //   course_name: string;
// // //   total_fee: number;
// // //   paid_amount: number;
// // //   balance_due: number;
// // // }

// // // interface CourseDetails {
// // //   payment_summary: PaymentSummary[];
// // //   id: string;
// // // }

// // // function Homepage() {
// // //   const stud_uuid: any = localStorage.getItem("uid");
// // //   const navigate = useNavigate();
// // //   const dispatch = useDispatch();
// // //   const [courseprogess, setCourseprogess] = useState(true);
// // //   const [loading, setLoading] = useState(true);
// // //   const [courseDetails, setCourseDetails] = useState<CourseDetails | null>(null);

// // //   const override: CSSProperties = {
// // //     flex: 1,
// // //     marginTop: 240,
// // //     justifyContent: "center",
// // //     alignItems: "center",
// // //   };

// // //   const fetchData = async () => {
// // //     setLoading(true);
// // //     try {
// // //       if (stud_uuid) {
// // //         const course_data = await getCourses(stud_uuid);
// // //         setCourseDetails(course_data);
// // //         Coursevalue(course_data);

// // //         const response = await studentPaymentDetailsBasedOnCourse(stud_uuid);

// // //         if (Array.isArray(response) && response.length > 0) {
// // //           setCourseDetails(prevState => ({
// // //             ...prevState ?? { id: "" },
// // //             payment_summary: response.map((course: any) => ({
// // //               course_name: course.course_name,
// // //               total_fee: course.total_fee,
// // //               paid_amount: course.paid_amount,
// // //               balance_due: course.balance_due,
// // //             })),
// // //           }));
// // //         }
// // //       } else {
// // //         navigate("/");
// // //       }
// // //     } catch (error) {
// // //       console.error("Error fetching data:", error);
// // //     } finally {
// // //       setLoading(false);
// // //     }
// // //   };

// // //   const Coursevalue = (props: any) => {
// // //     dispatch(
// // //       training_idData({
// // //         data: props.id,
// // //       })
// // //     );
// // //   };

// // //   useEffect(() => {
// // //     fetchData();
// // //   }, [stud_uuid]);

// // //   const handlePayNowClick = (courseName: string) => {
// // //     console.log(`Course Name: ${courseName}`);
// // //   };

// // //   return (
// // //     <div>
// // //       {!loading ? (
// // //         <>
// // //           <Header />
// // //           <p className="text-[45px] max-laptop:text-[40px] max-tablet:text-[30px] max-mobile:text-[18px] font-normal font-[roboto] text-left text-[#707070] ml-5 mt-10">
// // //             Stay Ahead with{" "}
// // //             <span className="font-bold text-[#000]">FITA Academy</span>{" "}
// // //           </p>
// // //           {courseDetails?.payment_summary && courseDetails.payment_summary.length > 0 && (
// // //             <div className="px-5 py-4 overflow-x-auto">
// // //               <div className="flex flex-nowrap  -ml-[15px]">
// // //                 {courseDetails.payment_summary.map((summary, index) => (
// // //                   <div
// // //                     key={index}
// // //                     className="flex items-center bg-white w-[430px] p-4 text-left flex-shrink-0 ml-3"
// // //                   >
// // //                     <div className="flex-shrink-0 w-[5px]  h-[60px]" style={{backgroundColor: '#F87171'}} />
// // //                     <div className="flex-1 shadow h-[60px] place-content-center">
// // //                       <h1 className="font-medium text-gray-900 ml-3">

// // //                         <span>{summary.course_name}</span>
// // //                       </h1>
// // //                       <p className="text-gray-500 ml-3"><span>Payment Overdue : </span> &#8377; {summary.balance_due.toFixed(2)}</p>
// // //                     </div>
// // //                     <button className="-ml-[90px] bg-blue-600 text-white rounded hover:bg-blue-700 transition px-2 py-1 text-sm"  onClick={() => handlePayNowClick(summary.course_name)}>
// // //                       Pay Now
// // //                     </button>
// // //                   </div>
// // //                 ))}
// // //               </div>
// // //             </div>
// // //           )}
// // //           <DreamJob />
// // //           {courseprogess ? (
// // //             <div className="bg-[#F9FCFF] text-left">
// // //               <CourseProgress />
// // //             </div>
// // //           ) : (
// // //             <div> </div>
// // //           )}
// // //           <Jobopening />
// // //           <Reasource />
// // //         </>
// // //       ) : (
// // //         <ClipLoader color="#036AD1" loading={loading} cssOverride={override} />
// // //       )}
// // //       <Footer />
// // //     </div>
// // //   );
// // // }

// // // export default Homepage;

// // import React, { useEffect, useState, CSSProperties, useRef } from "react";
// // import Header from "../elements/header";
// // import Reasource from "./reasource";
// // import Jobopening from "./jobopening";
// // import DreamJob from "./dreamjob";
// // import { useNavigate } from "react-router-dom";
// // import { useDispatch } from "react-redux";
// // import { training_idData } from "../../redux/useReducer";
// // import { studentPaymentDetailsBasedOnCourse } from "../../function/Student/CourseProgree/CourseProgress";
// // import CourseProgress from "./courseProgress";
// // import { getCourses } from "../../function/Student/Profile/Profile";
// // import ClipLoader from "react-spinners/ClipLoader";
// // import Footer from "../elements/Footer";
// // import rightArrow from '../../images/rightArrow.svg'


// // interface PaymentSummary {
// //   course_name: string;
// //   total_fee: number;
// //   paid_amount: number;
// //   balance_due: number;
// // }

// // interface CourseDetails {
// //   payment_summary: PaymentSummary[];
// //   id: string;
// // }

// // function Homepage() {
// //   const stud_uuid: any = localStorage.getItem("uid");
// //   const navigate = useNavigate();
// //   const dispatch = useDispatch();
// //   const [courseprogess, setCourseprogess] = useState(true);
// //   const [loading, setLoading] = useState(true);
// //   const [courseDetails, setCourseDetails] = useState<CourseDetails | null>(null);
// //   const scrollContainerRef = useRef<HTMLDivElement>(null);

// //   const override: CSSProperties = {
// //     flex: 1,
// //     marginTop: 240,
// //     justifyContent: "center",
// //     alignItems: "center",
// //   };

// //   const fetchData = async () => {
// //     setLoading(true);
// //     try {
// //       if (stud_uuid) {
// //         const course_data = await getCourses(stud_uuid);
// //         setCourseDetails(course_data);
// //         Coursevalue(course_data);

// //         const response = await studentPaymentDetailsBasedOnCourse(stud_uuid);

// //         if (Array.isArray(response) && response.length > 0) {
// //           setCourseDetails(prevState => ({
// //             ...prevState ?? { id: "" },
// //             payment_summary: response.map((course: any) => ({
// //               course_name: course.course_name,
// //               total_fee: course.total_fee,
// //               paid_amount: course.paid_amount,
// //               balance_due: course.balance_due,
// //             })),
// //           }));
// //         }
// //       } else {
// //         navigate("/");
// //       }
// //     } catch (error) {
// //       console.error("Error fetching data:", error);
// //     } finally {
// //       setLoading(false);
// //     }
// //   };

// //   const Coursevalue = (props: any) => {
// //     dispatch(
// //       training_idData({
// //         data: props.id,
// //       })
// //     );
// //   };

// //   useEffect(() => {
// //     fetchData();
// //   }, [stud_uuid]);

// //   const handlePayNowClick = (courseName: string) => {
// //     console.log(`Course Name: ${courseName}`);
// //   };

// //   const scrollLeft = () => {
// //     if (scrollContainerRef.current) {
// //       scrollContainerRef.current.scrollBy({
// //         left: -300, // Adjust the value for how much you want to scroll
// //         behavior: "smooth",
// //       });
// //     }
// //   };

// //   const scrollRight = () => {
// //     if (scrollContainerRef.current) {
// //       scrollContainerRef.current.scrollBy({
// //         left: 300, // Adjust the value for how much you want to scroll
// //         behavior: "smooth",
// //       });
// //     }
// //   };

// //   return (
// //     <div>
// //       {!loading ? (
// //         <>
// //           <Header />
// //           <p className="text-[45px] max-laptop:text-[40px] max-tablet:text-[30px] max-mobile:text-[18px] font-normal font-[roboto] text-left text-[#707070] ml-5 mt-10">
// //             Stay Ahead with{" "}
// //             <span className="font-bold text-[#000]">FITA Academy</span>{" "}
// //           </p>
// //           {courseDetails?.payment_summary && courseDetails.payment_summary.length > 0 && (
// //             <div className="relative px-5 py-4">
// //               <button
// //                 className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 rounded-full"
// //                 onClick={scrollLeft}
// //               >
// //                 &#8592; {/* Left arrow */}
// //               </button>
// //               <div ref={scrollContainerRef} className="flex flex-nowrap overflow-x-auto scrollbar-hide">
// //                 {courseDetails.payment_summary.map((summary, index) => (
// //                   <div
// //                     key={index}
// //                     className="flex items-center bg-white w-[430px] p-4 text-left flex-shrink-0 ml-3"
// //                   >
// //                     <div className="flex-shrink-0 w-[5px] h-[60px]" style={{ backgroundColor: '#F87171' }} />
// //                     <div className="flex-1 shadow h-[60px] place-content-center">
// //                       <h1 className="font-medium text-gray-900 ml-3">
// //                         <span>{summary.course_name}</span>
// //                       </h1>
// //                       <p className="text-gray-500 ml-3"><span>Payment Overdue: </span> &#8377; {summary.balance_due.toFixed(2)}</p>
// //                     </div>
// //                     <button className="-ml-[90px] bg-blue-600 text-white rounded hover:bg-blue-700 transition px-2 py-1 text-sm" onClick={() => handlePayNowClick(summary.course_name)}>
// //                       Pay Now
// //                     </button>
// //                   </div>
// //                 ))}
// //               </div>
// //               <button
// //                 className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-200 p-2 rounded-full shadow-md hover:bg-gray-300"
// //                 onClick={scrollRight}
// //               >
// //                 &#8594; {/* Right arrow */}
// //               </button>
// //             </div>
// //           )}
// //           <DreamJob />
// //           {courseprogess ? (
// //             <div className="bg-[#F9FCFF] text-left">
// //               <CourseProgress />
// //             </div>
// //           ) : (
// //             <div> </div>
// //           )}
// //           <Jobopening />
// //           <Reasource />
// //         </>
// //       ) : (
// //         <ClipLoader color="#036AD1" loading={loading} cssOverride={override} />
// //       )}
// //       <Footer />
// //     </div>
// //   );
// // }

// // export default Homepage;

// import React, { useEffect, useState, CSSProperties, useRef } from "react";
// import Header from "../elements/header";
// import Reasource from "./reasource";
// import Jobopening from "./jobopening";
// import DreamJob from "./dreamjob";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { training_idData } from "../../redux/useReducer";
// import { studentPaymentDetailsBasedOnCourse } from "../../function/Student/CourseProgree/CourseProgress";
// import CourseProgress from "./courseProgress";
// import { getCourses } from "../../function/Student/Profile/Profile";
// import ClipLoader from "react-spinners/ClipLoader";
// import Footer from "../elements/Footer";
// import rightArrow from '../../images/rightArrow.svg';

// interface PaymentSummary {
//   course_name: string;
//   total_fee: number;
//   paid_amount: number;
//   balance_due: number;
// }

// interface CourseDetails {
//   payment_summary: PaymentSummary[];
//   id: string;
// }

// function Homepage() {
//   const stud_uuid: any = localStorage.getItem("uid");
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [courseprogess, setCourseprogess] = useState(true);
//   const [loading, setLoading] = useState(true);
//   const [courseDetails, setCourseDetails] = useState<CourseDetails | null>(null);
//   const scrollContainerRef = useRef<HTMLDivElement>(null);

//   const override: CSSProperties = {
//     flex: 1,
//     marginTop: 240,
//     justifyContent: "center",
//     alignItems: "center",
//   };

//   const fetchData = async () => {
//     setLoading(true);
//     try {
//       if (stud_uuid) {
//         const course_data = await getCourses(stud_uuid);
//         setCourseDetails(course_data);
//         Coursevalue(course_data);

//         const response = await studentPaymentDetailsBasedOnCourse(stud_uuid);

//         if (Array.isArray(response) && response.length > 0) {
//           setCourseDetails(prevState => ({
//             ...prevState ?? { id: "" },
//             payment_summary: response.map((course: any) => ({
//               course_name: course.course_name,
//               total_fee: course.total_fee,
//               paid_amount: course.paid_amount,
//               balance_due: course.balance_due,
//             })),
//           }));
//         }
//       } else {
//         navigate("/");
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const Coursevalue = (props: any) => {
//     dispatch(
//       training_idData({
//         data: props.id,
//       })
//     );
//   };

//   useEffect(() => {
//     fetchData();
//   }, [stud_uuid]);

//   const handlePayNowClick = (courseName: string) => {
//     console.log(`Course Name: ${courseName}`);
//   };

//   const scrollLeft = () => {
//     if (scrollContainerRef.current) {
//       scrollContainerRef.current.scrollBy({
//         left: -300, // Adjust the value for how much you want to scroll
//         behavior: "smooth",
//       });
//     }
//   };

//   const scrollRight = () => {
//     if (scrollContainerRef.current) {
//       scrollContainerRef.current.scrollBy({
//         left: 300, // Adjust the value for how much you want to scroll
//         behavior: "smooth",
//       });
//     }
//   };

//   const filteredPaymentSummary = courseDetails?.payment_summary.filter((summary) => summary.balance_due > 0);

//   // console.log(courseDetails,'coursedetails');

//   return (
//     <div>
//       {!loading ? (
//         <>
//           <Header />
//           <p className="text-[45px] max-laptop:text-[40px] max-tablet:text-[30px] max-mobile:text-[18px] font-normal font-[roboto] text-left text-[#707070] ml-5 mt-10">
//             Stay Ahead with{" "}
//             <span className="font-bold text-[#000]">FITA Academy</span>{" "}
//           </p>
//           {filteredPaymentSummary && filteredPaymentSummary.length > 0 && (
//             <div className="relative px-5 py-4">
//               <button
//                 className="absolute left-0 top-1/2 transform -translate-y-1/2  rotate-180 w-[22px] ml-3"
//                 onClick={scrollLeft}
//               >
//                 <img src={rightArrow} alt="Left Arrow" style={{ width: '40px', height: '40px' }} /> {/* Adjust size here */}
//               </button>
//               <div ref={scrollContainerRef} className="flex flex-nowrap overflow-x-auto scrollbar-hide">
//                 {filteredPaymentSummary.map((summary, index) => (
//                   <div
//                     key={index}
//                     className="flex items-center bg-white w-[430px] p-4 text-left flex-shrink-0 ml-3"
//                   >
//                     <div className="flex-shrink-0 w-[5px] h-[60px]" style={{ backgroundColor: '#F87171' }} />
//                     <div className="flex-1 shadow h-[60px] place-content-center">
//                       <h1 className="font-medium text-gray-900 ml-3">
//                         <span>{summary.course_name}</span>
//                       </h1>
//                       <p className="text-gray-500 ml-3"><span>Payment Overdue: </span> &#8377; {summary.balance_due.toFixed(2)}</p>
//                     </div>
//                     <button className="-ml-[90px] bg-blue-600 text-white rounded hover:bg-blue-700 transition px-2 py-1 text-sm" onClick={() => handlePayNowClick(summary.course_name)}>
//                       Pay Now
//                     </button>
//                   </div>
//                 ))}
//               </div>
//               <button
//                 className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-3"
//                 onClick={scrollRight}
//               >
//                 <img src={rightArrow} alt="Right Arrow" />
//               </button>
//             </div>
//           )}
//           <DreamJob />
//           {courseprogess ? (
//             <div className="bg-[#F9FCFF] text-left">
//               <CourseProgress />
//             </div>
//           ) : (
//             <div> </div>
//           )}
//           <Jobopening />
//           <Reasource />
//         </>
//       ) : (
//         <ClipLoader color="#036AD1" loading={loading} cssOverride={override} />
//       )}
//       <Footer />
//     </div>
//   );
// }

// export default Homepage;



import React, { useEffect, useState, CSSProperties, useRef } from "react";
import Header from "../elements/header";
import Reasource from "./reasource";
import Jobopening from "./jobopening";
import DreamJob from "./dreamjob";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { training_idData } from "../../redux/useReducer";
import { studentPaymentDetailsBasedOnCourse } from "../../function/Student/CourseProgree/CourseProgress";
import CourseProgress from "./courseProgress";
import { getCourses } from "../../function/Student/Profile/Profile";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from "../elements/Footer";
import rightArrow from '../../images/rightArrow.svg';

interface PaymentSummary {
  course_name: string;
  total_fee: number;
  paid_amount: number;
  balance_due: number;
}

interface CourseDetails {
  payment_summary: PaymentSummary[];
  id: string;
}

function Homepage() {
  const stud_uuid: any = localStorage.getItem("uid");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [courseprogess, setCourseprogess] = useState(true);
  const [loading, setLoading] = useState(true);
  const [courseDetails, setCourseDetails] = useState<CourseDetails | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const override: CSSProperties = {
    flex: 1,
    marginTop: 240,
    justifyContent: "center",
    alignItems: "center",
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      if (stud_uuid) {
        const course_data = await getCourses(stud_uuid);
        setCourseDetails(course_data);
        Coursevalue(course_data);

        const response = await studentPaymentDetailsBasedOnCourse(stud_uuid);

        if (Array.isArray(response) && response.length > 0) {
          setCourseDetails(prevState => ({
            ...prevState ?? { id: "" },
            payment_summary: response.map((course: any) => ({
              course_name: course.course_name,
              total_fee: course.total_fee,
              paid_amount: course.paid_amount,
              balance_due: course.balance_due,
            })),
          }));
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const Coursevalue = (props: any) => {
    dispatch(
      training_idData({
        data: props.id,
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, [stud_uuid]);

  const handlePayNowClick = (courseName: string) => {
    console.log(`Course Name: ${courseName}`);
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -300, // Adjust the value for how much you want to scroll
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 300, // Adjust the value for how much you want to scroll
        behavior: "smooth",
      });
    }
  };

  // Safely access courseDetails.payment_summary with a fallback to an empty array
  const filteredPaymentSummary = (courseDetails?.payment_summary || []).filter(
    (summary) => summary.balance_due > 0
  );

  return (
    <div>
      {!loading ? (
        <>
          <Header />
          <p className="text-[45px] max-laptop:text-[40px] max-tablet:text-[30px] max-mobile:text-[18px] font-normal font-[roboto] text-left text-[#707070] ml-5 mt-10">
            Stay Ahead with{" "}
            <span className="font-bold text-[#000]">FITA Academy</span>{" "}
          </p>
          {filteredPaymentSummary.length > 0 && (
            <div className="relative px-5 py-4">
              <button
                className="absolute left-0 top-1/2 transform -translate-y-1/2 rotate-180 w-[22px] ml-3"
                onClick={scrollLeft}
              >
                <img src={rightArrow} alt="Left Arrow" style={{ width: '40px', height: '40px' }} /> {/* Adjust size here */}
              </button>
              <div ref={scrollContainerRef} className="flex flex-nowrap overflow-x-auto scrollbar-hide">
                {filteredPaymentSummary.map((summary, index) => (
                  <div
                    key={index}
                    className="flex items-center bg-white w-[430px] p-4 text-left flex-shrink-0 ml-3"
                  >
                    <div className="flex-shrink-0 w-[5px] h-[60px]" style={{ backgroundColor: '#F87171' }} />
                    <div className="flex-1 shadow h-[60px] place-content-center">
                      <h1 className="font-medium text-red-600 ml-3">
                        <span>Payment Overdue: </span>&#8377; {summary.balance_due.toFixed(2)}
                      </h1>
                      <p className="text-gray-500 ml-3 text-xs"><span>{summary.course_name} </span></p>
                    </div>
                    <button className="-ml-[90px] bg-blue-600 text-white rounded hover:bg-blue-700 transition px-2 py-1 text-sm" onClick={() => handlePayNowClick(summary.course_name)}>
                      Pay Now
                    </button>
                  </div>
                ))}
              </div>
              <button
                className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-3 -mt-3"
                onClick={scrollRight}
              >
                <img src={rightArrow} alt="Right Arrow" />
              </button>
            </div>
          )}
          <DreamJob />
          {courseprogess ? (
            <div className="bg-[#F9FCFF] text-left">
              <CourseProgress />
            </div>
          ) : (
            <div> </div>
          )}
          <Jobopening />
          <Reasource />
        </>
      ) : (
        <ClipLoader color="#036AD1" loading={loading} cssOverride={override} />
      )}
      <Footer />
    </div>
  );
}

export default Homepage;
